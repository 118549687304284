// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import creators from './creators';
import transactions from './transactions';
import statistics from './statistics';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  creators,
  statistics,
  transactions
});

export default reducers;
