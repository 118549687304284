import { setCreators, updateCreatorStatus } from 'store/reducers/creators';
import { setTransactions, updateTransactionStatus } from 'store/reducers/transactions';
import { auth, functions, storage } from './firebase';
import { httpsCallable } from "firebase/functions";
import { store } from 'store';
import { setStatistics } from 'store/reducers/statistics';

export function getCreatorApplications() {
    return new Promise((resolve, reject) => {
        const getCreatorApplicationsCloudFunction = httpsCallable(functions, 'creator-getCreatorApplications');
        getCreatorApplicationsCloudFunction()
        .then(async (result) => {
            
            console.log(result)
            if (result.data) {
                store.dispatch(setCreators(result.data))
            }
            
            resolve(result)

        }).catch((error) => {
        console.log(error);
        reject(error)
    })
    })
}

export function approveCreator(applicationId) {
    return new Promise((resolve, reject) => {
        const approveCreatorCloudFunction = httpsCallable(functions, 'creator-approveCreator');
        approveCreatorCloudFunction({applicationId})
        .then(async (result) => {
            
            //console.log(result)
            // if (result.data) {
            //     store.dispatch(setCreators(result.data))
            // }
            

            store.dispatch(updateCreatorStatus({applicationId, status: "approved"}))

            //EMAIL SYSTEM
            var userLang = navigator.language || navigator.userLanguage; 
            const sendMailToUser = httpsCallable(functions, 'emailServices-sendMailToUser');
            sendMailToUser({language: userLang, template: 'creatorApprovedEmailTemplate', extraData: {}})
            .then((result) => {
                // Read result of the Cloud Function.
                /** @type {any} */
                const data = result.data;
                const sanitizedMessage = data.text;
                // resolve(sanitizedMessage)
            })
            //EMAIL SYSTEM END
            resolve(result)



        }).catch((error) => {
        console.log(error);
        reject(error)
    })
    })
}

export function getUserStatistics() {
    const getUserStatisticsCloudFunction = httpsCallable(functions, 'adminEndpoints-getUserStatistics');
    getUserStatisticsCloudFunction()
    .then(async (result) => {
        store.dispatch(setStatistics(result.data))
    }).catch((error) => {
        console.log(error)
    console.log(error);
    })

}

export function getTransactionsEffect() {
    return new Promise((resolve, reject) => {
        const getTransactions = httpsCallable(functions, 'adminEndpoints-getTransactions');
        getTransactions({ month: '2023-10' })
        .then((result) => {
        const transactionsByCreator = result.data;
        console.log("TRANSACTIONS::", transactionsByCreator);
        if (transactionsByCreator) {
            store.dispatch(setTransactions(result.data))
        }
        resolve(transactionsByCreator)
        })
        .catch((error) => {
        // console.error('Error calling the function:', error);
        reject(error)
        });
    });
}