import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

import {auth } from "../firebase"

import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

// third-party

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';

// action - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';

// firebase initialize


// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(
    () =>
     auth.onAuthStateChanged((user) => {
        if (user) {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: {
                id: user.uid,
                email: user.email,
                name: user.displayName || 'Stebin Ben',
                role: 'UI/UX Designer'
              }
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const firebaseEmailPasswordSignIn = (email, password) => signInWithEmailAndPassword(auth, email, password);

  const firebaseGoogleSignIn = () => {
    const provider = new auth.GoogleAuthProvider();
    return auth.signInWithPopup(provider);
  };

  const firebaseRegister = async (email, password) => createUserWithEmailAndPassword(auth, email, password);

  const logout = () => auth.signOut();

  const resetPassword = async (email) => {
    await auth.sendPasswordResetEmail(email);
  };

  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => {console.log("login called")},
        firebaseGoogleSignIn,
        logout,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node
};

export default FirebaseContext;
