import { lazy } from 'react';
import Loadable from 'components/Loadable';


// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page
const MainInfo = Loadable(lazy(() => import('pages/extra-pages/main-info')));
//const Creators = Loadable(lazy(() => import('pages/extra-pages/creators')));
const AppCustomerList = Loadable(lazy(() => import('pages/apps/customer/list')));
const AppTransactionsList = Loadable(lazy(() => import('pages/apps/transactions/List')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'main-info',
          element: <MainInfo />
        },
        {
          path: 'creators',
          element: <AppCustomerList />
        },
        {
          path: 'payouts',
          element: <AppTransactionsList />
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
