import { auth } from "./firebase"
import store from "./store"
import { getCreatorApplications, getOwnUser, getUserStatistics, getTransactionsEffect } from "./sideEffects"

// import { httpsCallable } from "firebase/functions";
// import { functions } from './firebase';

auth.onAuthStateChanged(function (user) {
  if (user) {    
    if(user.uid) {
      getCreatorApplications()
      getUserStatistics()
      getTransactionsEffect();
      
    }
      } else {
    // No user is signed in.
  }
});