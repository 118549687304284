// project import
import basics from './basics';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [basics]
};

export default menuItems;
