import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  transactions: []
};

// ==============================|| SLICE - transactions ||============================== //

const transactions = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactions(state, action) {
      const _newTransactions = action.payload;
      state.transactions = _newTransactions;
    },
    updateTransactionStatus(state, action) {
      const { applicationId, status } = action.payload;
      const _newTransactions = state.transactions.map((transaction) => {
        if (transaction.applicationId === applicationId) {
          transaction.status = status;
        }
        return transaction;
      }
      );
    }
  }
});

export default transactions.reducer;

export const { setTransactions, updateTransactionStatus } = transactions.actions;
