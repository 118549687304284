// import { Link as RouterLink } from 'react-router-dom';

// // material-ui
//import { Link, Stack, Typography } from '@mui/material';

const Footer = () => (

  <div></div>

);

export default Footer;
