import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  statistics: []
};

// ==============================|| SLICE - statistics ||============================== //

const statistics = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatistics(state, action) {
      const _newStatistics = action.payload;
      state.statistics = _newStatistics;
    },
    updateCreatorStatus(state, action) {
      const { applicationId, status } = action.payload;
      const _newStatistics = state.statistics.map((creator) => {
        if (creator.applicationId === applicationId) {
          creator.status = status;
        }
        return creator;
      }
      );
    }
  }
});

export default statistics.reducer;

export const { setStatistics, updateCreatorStatus } = statistics.actions;
