import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  creators: []
};

// ==============================|| SLICE - creators ||============================== //

const creators = createSlice({
  name: 'creators',
  initialState,
  reducers: {
    setCreators(state, action) {
      const _newCreators = action.payload;
      state.creators = _newCreators;
    },
    updateCreatorStatus(state, action) {
      const { applicationId, status } = action.payload;
      const _newCreators = state.creators.map((creator) => {
        if (creator.applicationId === applicationId) {
          creator.status = status;
        }
        return creator;
      }
      );
    }
  }
});

export default creators.reducer;

export const { setCreators, updateCreatorStatus } = creators.actions;
