
// material-ui
import zipper from './zipper.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({size}) => {
  return ( <img src={zipper} alt="logo" style={{width: size, height: size}}/>);
};


export default LogoMain;
