// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const basics = {
  id: 'basics',
  title: <FormattedMessage id="basics" />,
  type: 'group',
  children: [
    {
      id: 'main-info',
      title: <FormattedMessage id="main-info" />,
      type: 'item',
      url: '/main-info',
      icon: icons.ChromeOutlined
    },
    {
      id: 'creators',
      title: <FormattedMessage id="creators" />,
      type: 'item',
      url: '/creators',
      icon: icons.DeploymentUnitOutlined
    },
    {
      id: 'payouts',
      title: <FormattedMessage id="Payouts" />,
      type: 'item',
      url: '/payouts',
      icon: icons.GatewayOutlined
    },
  ]
};

export default basics;
