import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
}

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

if (['127.0.0.1', 'localhost', '0.0.0.0', 'mac.local', 'local', 'mac'].includes(window.location.hostname)) {
    let hostName = window.location.hostname
    console.log("DEBUG Mode")
    connectFirestoreEmulator(db, hostName, 8999);
    connectAuthEmulator(auth, 'http://' + hostName + ':9099', { disableWarnings: true });
    connectFunctionsEmulator(functions, hostName, 5001);
    connectStorageEmulator(storage, hostName, 9199);
}

export default app
export { db, auth, functions, storage }